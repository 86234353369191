.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-contactus{

    position: relative;
}
.bg-contactus-img{
  opacity: 0.8;

}
.bg-img-title {
  position: absolute;
  top: 200px;

}

.font-nunito {
  font-family: 'Nunito', sans-serif;
  font-weight: black;
}
.font-Bebas {
  font-family: 'Bebas Neue', cursive;
}
.orderItemMenu{
  display: flex;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;

}
.menuNavBar {
  display: flex;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;

}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .OrderPageNumberBox {
    visibility: hidden;
  }
}
@media only screen and (max-width: 1024px) {
  /* For mobile phones: */
  .orderRowItemClass {
    margin: auto;
    width: 100%;
  }
}
.contactUsBlockbg{
  background-image: rgb(17, 24, 39,0.8 ) !important;
}
/* ///////////// */



@media screen and (max-width: 414px) and (min-width: 394px) {

  .orderPageNavBar{
    width: 395px;
  }
  .orderPageItemImage{
    width: 150px;
    /* height: auto; */
  }
  .orderPageItemTitle{
 font-size: 12px;
 line-height: 15px;
  }
}

@media screen and (max-width: 393px) and (min-width: 391px) {

  .orderPageNavBar{
    width: 370px;
  }
  .orderPageItemImage{
    width: 150px;
    /* height: auto; */
  }
  .orderPageItemTitle{
 font-size: 12px;
 line-height: 15px;
  }
}

@media screen and (min-width: 376px) and (max-width: 389px) {

  .orderPageNavBar{
    width: 370px;
  }
  .orderPageItemImage{
    width: 150px;
    /* height: auto; */
  }
  .orderPageItemTitle{
 font-size: 12px;
 line-height: 15px;
  }
}

@media screen and (min-width: 390px) and (max-width: 392px) {

  .orderPageNavBar{
    width: 360px;
  }
  .orderPageItemImage{
    width: 150px;
    /* height: auto; */
  }
  .orderPageItemTitle{
 font-size: 12px;
 line-height: 15px;
  }
}

@media screen and (max-width: 375px) and (min-width:361px) {

  .orderPageNavBar{
    width: 350px !important;
    padding: 0p;
  }
  .orderPageItemImage{
    width: 150px;
    /* height: auto; */
  }
  .orderPageItemTitle{
 font-size: 12px;
 line-height: 15px;
  }
 
}
@media screen and (max-width: 360px) and (min-width:281px)  {

  .orderPageNavBar{
    width: 340px;
  }
  .orderPageItemImage{
    width: 150px;
    /* height: auto; */
  }
  .orderPageItemTitle{
 font-size: 12px;
 line-height: 15px;
  }
}

@media screen and (max-width: 280px)  {

  .orderPageNavBar{
    width: 250px;
  }
  .orderPageItemImage{
    width: 150px;
    /* height: auto; */
  }
  .orderPageItemTitle{
 font-size: 12px;
 line-height: 15px;
  }
}

/* --------- home page ---------- */
/* --------- desk component ---------- */
@media screen and (max-width: 414px) and (min-width: 361px){

  .homePageButtonsGroup{
    padding: 8px 0px !important;
    font-size: 12px !important;
    background-color: red;
  }
}

@media screen and (max-width: 360px){

  .homePageButtonsGroup{
    padding: 8px 0px !important;
    font-size: 10px !important;
  }
}

@media screen and (max-width: 912px) and (min-width: 768px){

  .homePageButtonsDiv{
    padding: 0px;
  }
}
/* --------- burger component ---------- */
@media screen and (max-width: 600px){

  .homePageBurgerDetails{
    margin-top: -40px !important;
    width: auto !important;
    padding: 15px;
    margin: auto;
  }
}
/* --------- contact us component ---------- */
@media screen and (max-width: 539px){

  .contactUsContentDiv{
    margin-left: 10px;
    margin-right: 10px;
  }
  .contactUsTitleDiv{
    font-size: 40px;
    padding: 0px;
    margin: 0px;
    text-align: center;
  }
}

.phoneNumber{
 text-decoration: none;
 color: rgb(250 204 21 / var(--tw-text-opacity)) !important;
}
.phoneNumberfooter{
  font-size: 0.70rem;
  color: #e5e7eb !important;
}
.phoneNumberContactus{
  margin-top: -20px !important;
  padding-top: -20px !important;
  color:  rgb(156 163 175 / var(--tw-text-opacity)) !important;
}

.picture{
  font: normal 14px Arial, Helvetica, sans-serif;
  font-family: var(--secondary-font);
  font-size: 14px;
  text-align: left;
  cursor: pointer;
  color: #2A3842;
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
  float: right;
  width: 180px;
  z-index: 1;
  height: auto;
  background-position: center;
  background-size: cover;
}
.details{
 
  font: normal 14px Arial, Helvetica, sans-serif;
  font-family: var(--secondary-font);
  font-size: 14px;
  text-align: left;
  cursor: pointer;
  color: #2A3842;
  margin: 0;
  text-decoration: none;
  box-sizing: border-box;
  padding: 15px 0 15px 15px;
  width: calc(100% - 150px);
  display: inline-block;
}
.items-box{
  font: normal 14px Arial, Helvetica, sans-serif;
  font-family: var(--secondary-font);
  font-size: 14px;
  color: var(--main-text-color);
  text-align: left;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
  position: relative;
  float: left;
  height: 150px;
  transition: 0.3s;
  margin: 0 0 30px -11px;
  width: calc(100% + 22px);
  background-color: #a0a0a0;
}
.productListOrder{
  
  font: normal 14px Arial, Helvetica, sans-serif;
  font-family: var(--secondary-font);
  font-size: 14px;
  color: var(--main-text-color);
  text-align: left;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  margin: 15px -1% 0 -1%;
}
.product-list-content{

  font: normal 14px Arial, Helvetica, sans-serif;
  font-family: var(--secondary-font);
  font-size: 14px;
  color: var(--main-text-color);
  text-align: left;
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
}
.WoffleLogoWithoutGlowheader{

}
